import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../Style/Header/Header.css'
import '../../Style/Header/whatsappButton.css'
import BackDrop from '../SideMenu/BackDrop'
import SideMenu from '../SideMenu/SideMenu'
import tripgix from '../../images/LandingPage/logo/tripgix.png'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import whatsappImage from '../../images/LandingPage/whatsapp.png';
import PopupFormBootstrap from '../PopupFormBootstrap'

const Header = () => {
    const [navbar, setNavbar] = useState(false);
    const [sideMenu, setSidemenu] = useState(false);
    const [domain, setDomain] = useState(false);
    const [allCategory, setAllCategory] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isMobileWhatsappVisible, setIsMobileWhatsappVisible] = useState(false);

    // get all category
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            if (res.data.data) {
                setAllCategory(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const NavbarBgChange = () => {
        if (window.scrollY >= 60) {
            setNavbar(true)
            setDomain(true)
        } else {
            setNavbar(false)
            setDomain(false)
        }
    }

    const WhatsappDisplayChange = () => {
        if (window.scrollY >= 200) {
            setWhatsapp(true)
            setIsMobileWhatsappVisible(true)
        } else {
            setWhatsapp(false)
            setIsVisible(false)
            setIsMobileWhatsappVisible(false)
        }
    }
    window.addEventListener('scroll', NavbarBgChange)
    window.addEventListener('scroll', WhatsappDisplayChange)

    const toggleMenu = () => {
        setSidemenu(!sideMenu)
    }

    useEffect(() => {
        getAllCategory();
    }, [])

    const handleButtonClick = () => {
        setIsVisible(!isVisible);
    };

    const TalkToExpert = (event) => {
        event.stopPropagation();
        const phoneNumber = '+918383080860';
        window.location.href = `tel:${phoneNumber}`;
    }
    const ChatWithUs = (event) => {
        event.stopPropagation();
        const phoneNumber = '+917982328247';
        const message = 'Hey Tripgix, I was exploring your website and i had some queries.';
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(url);
    }
    const SendQuery = (event) => {
        event.stopPropagation();
    }

    return (
        <>
            <BackDrop sideMenu={sideMenu} closeSideMenu={toggleMenu} />
            <SideMenu sideMenu={sideMenu} setSidemenu={setSidemenu} />
            <nav className={`main navbar ${navbar ? "active" : ""} fixed-top`}>
                <Link to='/'>
                    <div className="logo d-flex align-items-center">
                        {
                            domain ?
                                <h6 className='text-white m-0'><b>Tripgix.com</b></h6>
                                :
                                <img width={60} src={tripgix} alt="" />
                        }
                    </div>
                </Link>
                <div className="contact_number"><span><i className='me-2 fa-solid fa-phone'></i><Link to="tel:+918383080860" className='text-white'>+91 8383-0808-60</Link></span></div>
                <div className="menu_list d-none d-lg-block">
                    <ul className='d-flex'>
                        {
                            allCategory && allCategory?.map((obj) => {
                                return <li key={obj._id}>
                                    <Link to={`/category/${(obj.name).replace(/\s+/g, "-")}`}>{obj.name}</Link>
                                </li>
                            }).slice(allCategory.length - 2, allCategory.length)
                        }
                        <li>
                            <Link to='/blog'>BLOGS</Link>
                        </li>
                        <li>
                            <Link to='/about'>ABOUT US</Link>
                        </li>
                        <li>
                            <Link to='/contact'>CONTACT US</Link>
                        </li>
                    </ul>
                </div>
                <div className="nav_toggle_button d-lg-none" onClick={toggleMenu}>
                    {
                        sideMenu ?
                            <CloseIcon />
                            :
                            <MenuIcon />
                    }
                </div>
            </nav>

            {whatsapp &&
                <div className='whatsapp-button' onClick={handleButtonClick}>
                    <div>
                        <div className='whatsapp-button-div'>
                            <img src={whatsappImage} alt="whatsapp button" />
                        </div>
                    </div>
                </div>
            }
            {isVisible &&
                <div className='whatsapp-div' onClick={handleButtonClick}>
                    <div className='py-3 bg-warning' style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                        <h4 className='text-center m-0' style={{ fontWeight: 500, fontSize: '21px' }}>Connect with Us</h4>
                    </div>
                    <div className='p-2 whatsapp-div-div'>
                        <div className='d-flex justify-content-center align-items-center py-2 whatsapp-div-text mx-2' onClick={TalkToExpert}>
                            <i className="fa-solid fa-phone me-4" style={{ fontSize: '20px', color: 'rgb(46, 171, 213)' }}></i>
                            <div className='m-0 d-flex flex-column justify-content-center align-items-center'>
                                <p className='m-0' style={{ fontSize: '18px' }}><>Talk To Expert</></p>
                                <p className='m-0' style={{ fontSize: '13px', fontWeight: 500 }}><>+91 8383-0808-60</></p>
                            </div>
                        </div>
                        <hr className='m-0 mx-2' style={{ color: 'gray' }} />
                        <div className='d-flex justify-content-center align-items-center py-3 whatsapp-div-text mx-2' onClick={ChatWithUs}>
                            <i className="fa-brands fa-whatsapp text-success me-4" style={{ fontSize: '28px' }}></i><p className='m-0' style={{ fontSize: '18px' }}><>Chat With Us</></p>
                        </div>
                        <hr className='m-0 mx-2' style={{ color: 'gray' }} />
                        <div className='d-flex justify-content-center align-items-center py-3 whatsapp-div-text mx-2' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={SendQuery}>
                            <i className="fa-solid fa-headset text-dark me-4" style={{ fontSize: '26px' }}></i><p className='m-0' style={{ fontSize: '18px' }}><>Send Query</></p>
                        </div>
                        <hr className='m-0 mx-2' style={{ color: 'gray' }} />
                    </div>
                </div>
            }

            {isMobileWhatsappVisible &&
                <div className='d-block d-md-none d-lg-none mobile-whatsapp-button m-0 d-flex justify-content-center align-items-center'>
                    <div className='mobile-whatsapp-wrap-1 m-0 d-flex justify-content-center align-items-center flex-column pt-1' style={{ height: '100%', borderTop: '1px solid gray' }} onClick={TalkToExpert}>
                        <i className="fa-solid fa-phone" style={{ fontSize: '18px', color: 'rgb(46, 171, 213)' }}></i>
                        <p className='m-0 mt-1' style={{ fontSize: '13px', fontWeight: 500 }}>Talk To Expert</p>
                    </div>
                    <div className='mobile-whatsapp-wrap-2 m-0 d-flex justify-content-center align-items-center flex-column pt-1' style={{ borderRight: '1px solid gray', height: '100%', borderTop: '1px solid gray', borderLeft: '1px solid gray' }} onClick={ChatWithUs}>
                        <i className="fa-brands fa-whatsapp text-success" style={{ fontSize: '22px' }}></i>
                        <p className='m-0 mt-1' style={{ fontSize: '13px', fontWeight: 500 }}>Chat With Us</p>
                    </div>
                    <div className='mobile-whatsapp-wrap-3 m-0 d-flex justify-content-center align-items-center flex-column pt-1' data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ height: '100%', borderTop: '1px solid gray' }} onClick={SendQuery}>
                        <i className="fa-solid fa-headset text-dark" style={{ fontSize: '22px' }}></i>
                        <p className='m-0 mt-1' style={{ fontSize: '13px', fontWeight: 500 }}>Send Query</p>
                    </div>
                </div>
            }

            <PopupFormBootstrap />
        </>

    )
}



export default Header
