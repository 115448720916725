import React from 'react';
import '../Style/Payment/Payment.css'

const Payment = () => {
    return (
        <div className='d-flex justify-content-center align-items-center Mon_font bg-dark text-white payment__page'>
            <h2>This is payment page</h2>
        </div>
    )
}

export default Payment
