import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import {Link} from 'react-router-dom'

const PhonePeIntegration = () => {
  const [amount, setAmount] = useState();
  const [checksum, setCheckum] = useState(null);
  const [base64EncodedPayload, setBase64EncodedPayload] = useState(null);

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  function generateTransactionID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000); 
    const merchantPrefix = 'TRIPGIX'; 
    const transactionID = `${merchantPrefix}_${timestamp}_${randomNum}`;
  
    return transactionID;
}

  const handleSubmit = (e)=>{
    e.preventDefault();
    axios({
            method: "POST",
            data: {
              amount: amount,
              transactionID: generateTransactionID(),
              userID: 'MUID-TRIPGIX-0123899128219',
              number: '7498608775'
            },
            withCredentials: true,
            url: `api/trips/new-payment`,
        }).then((res) => {
            window.open(res.data, "_blank");
        }).catch((err) => {
            console.log(err);
        });
  }

  return (
    <div className="App mt-5">
      <center>
        <form onSubmit={handleSubmit}>
            <label htmlFor="name">Amount: </label>
            <input
                className='m-3'
                type="number"
                name="payment_amount"
                onChange={(event) =>
                    handleChange(event)
                }
            />
            <br />
            <button className='' type="submit" name='submit_form'>Submit</button>
        </form>
        {/* <form
            action="http://localhost:8000/phonepe.php"
            method="post"
        >
            <label htmlFor="name">Amount: </label>
            <input
                className='m-3'
                type="text"
                name="payment_amount"
                onChange={(event) =>
                    handleChange(event)
                }
            />
            <br />
            <button className='' type="submit" name='submit_form'>Submit</button>
        </form> */}
      </center>
    </div>
  )
}

export default PhonePeIntegration
