import React from "react";
import "../../Style/LandingPage/Testimonial.css";

const Testmonials = () => {
  return (
    <div className="Mon_font testimonials__cont my-5 py-5">
      <div className="text-center ms-2">
        <h2>
          What Our Travellers <br className="d-block d-lg-none d-md-block" />{" "}
          Feel About Us
        </h2>
        <div className="hr-line mb-3"></div>
        <p className="text-center">Testimonial & Reviews</p>
      </div>
      <div className="testimonials">
        <div className="text-center">
          <iframe
            src="https://www.youtube.com/embed/LgsQc3Tp18g?si=rJdO54ts2vGRplZE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Testmonials;
