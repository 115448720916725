import React, { useEffect, useState } from 'react'
import Layout from '../../components/Admin/AdminLayout'
import AdminHeader from './component/AdminHeader'
import { message } from 'antd';
import axios from 'axios';

const AddCityImage = () => {
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState(null);
    const [data, setData] = useState(null)
    const [uniqueCity, setUniqueCity] = useState(null);
    const [allCategory, setAllCategory] = useState(null);

    //get All trip for unique location
     const getAllTrips = async () => {
        await axios.get(`api/trips/get-all-trips`).then((res) => {
            let locations = []
            if(res.data.data){
                res.data?.data?.forEach(trip => {
                    locations.push(trip?.location);
                });
            }
            setUniqueCity([...new Set(locations)])
            
            }).catch((err) => {
                console.log(err);
            });
    }


    // banner image cloudinary API
    const postDetails = (image) => {
        if (!image) {
            return message.error('Please Select an image');
        }
        if (image.type === 'image/jpeg' || image.type === 'image/png') {
            const data = new FormData();
            data.append('file', image);
            data.append('upload_preset', 'onlinelecture');
            data.append('cloud_name', 'Krantikaari');
            setLoading(true)
            fetch('https://api.cloudinary.com/v1_1/dn5oapayl/image/upload/', {
                method: 'post',
                body: data,
            }).then((res) => res.json()).then((data) => {
                setLoading(false)
                setInputFields({ ...inputFields, image: data.secure_url })
            }).catch((err) => {
                message.error(err);
            })
        } else {
            return message.error('Please Select an image');
        }
    };

    // on form value change
    const handleChange = (event, value)=>{
        if (value === 'cityImage') {
            postDetails(event.target.files[0])
        }
        setInputFields({ ...inputFields, [event.target.name]: event.target.value })
    }

    //on form submit
    const submitHandler = (e)=>{
        e.preventDefault();
        setLoading(true)
        axios({
            method: "POST",
            data: {
                city: inputFields.city,
                image: inputFields.image,
            },
            withCredentials: true,
            url: `api/cityImages/add-city-image`,
        }).then((res) => {
            getAllCityImage()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }

    // on banner delete
    const deleteCityImage = (id)=>{
        axios({
            method: "POST",
            data: {
                id: id,
            },
            withCredentials: true,
            url: `api/cityImages/delete-city-image`,
        }).then((res) => {
            getAllCityImage()
            message.success(res.data.message)
        }).catch((err) => {
            console.log(err);
        });
    }

    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            let categories = []
            if(res.data.data){
                res.data?.data.forEach(category => {
                    categories.push(category.name);
                });
            }
            setAllCategory(categories);
            
            }).catch((err) => {
                console.log(err);
            });
    }

    const getAllCityImage = ()=>{
        axios({
            method: "GET",
            withCredentials: true,
            url: `api/cityImages/get-all-city-image`,
        }).then((res) => {
            setData(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(()=>{
        getAllCityImage()
        getAllTrips()
        getAllCategory()
        // eslint-disable-next-line
    }, [])
  return (
    <Layout>
        <AdminHeader title='All City Images' redirect='/admin-dashboard' btnName='Add Banner' btnUrl='/promotional-banner' btnName2='Add Coupon Code' btnUrl2='/add-coupon-code'/>
        <div className="container Mon_font px-4">
            <form className="" style={{ backgroundColor: "#fff" }} onSubmit={submitHandler}>
                <div className="col-3 my-3">
                    <label htmlFor="location" className="form-label">Select City</label>
                    <select className="form-select" value={inputFields?.city} required name="city" onChange={(event) => { handleChange(event) }}>
                        <option value="">Select</option>
                        {/* {uniqueCity && uniqueCity.map((city)=>{
                                return (
                                    <option value={city}>{city}</option>
                                )
                            })} */}
                        {allCategory && allCategory.map((category)=>{
                                return (
                                    <option value={category}>{category}</option>
                                )
                            })}
                    </select>
                </div>
                <div className="col-3 my-3">
                    <label htmlFor="tripImage" className="form-label">Add City Image</label>
                    <input required type="file" accept="image/*" className="form-control" id="tripImage" onChange={(event) => { handleChange(event, 'cityImage') }} />
                    {inputFields?.image && <div className="col-4 my-2">
                    <img width={340} src={inputFields.image} alt="Loading" />
                    </div>}
                </div>
                <hr />
                <div className='my-2'>
                    <button disabled={loading === true} type='submit' className='btn btn-warning w-100'>{loading? 'Loading...' : 'Submit'}</button>
                </div>
            </form>
        </div>

        {/* all banners */}
        <div className="container Mon_font px-4 pb-4">
            <table className='table table-light table-bordered w-100 m-0 mt-4'>
                <thead className='p-2 py-3 table-secondary'>
                    <tr>
                        <th scope="col" className='py-2'>image</th>
                        <th scope="col" className='py-2'>City</th>
                        <th scope="col" className='py-2'>Action</th>
                    </tr>
                </thead>
                <tbody>
                        {data?.map((data)=>{
                        return <tr className='m-2' key={data._id}>
                                <td className=''>
                                    <img className='m-2' width={300} src={data.image} alt="Loading" />
                                </td>
                                <td className='text-danger'>{data.city}</td>
                                <td className='ps-4'>
                                    <div className="flex gap-2">
                                        <i className="fa-sharp fa-solid fa-trash cursor-pointer p-2"  onClick={() => deleteCityImage(data._id)}></i>
                                    </div>
                                </td>
                        </tr>
                        })
                        }
                </tbody>
            </table>
        </div>
    </Layout>
  )
}

export default AddCityImage
