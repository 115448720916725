import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeroSection from "../LandingPage/HeroSection";
import "../../Style/OtherLayout/OtherLayout.css";
import { useLocation } from "react-router-dom";
import "../../Style/career/Career.css";

const OtherLayout = ({
  children,
  title,
  subTitle,
  activeTrip,
  setExpertForm,
  background,
  extraSubTitle,
  aboutSubTitle,
  btn1,
  btn2,
  btn3,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const isAbout = location.pathname === "/about";
  const isContact = location.pathname === "/contact";
  const isBlog = location.pathname === "/blog";
  const isCareer = location.pathname === "/career";
  const isCategory = location.pathname === "/category";
  const isHome = location.pathname === "/";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ChatWithUs = (event) => {
    event.stopPropagation();
    const phoneNumber = "917982328247";
    const message =
      "Hey Tripgix, I was exploring your website and i had some queries.";
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url);
  };

  const TalkToExpert = (event) => {
    event.stopPropagation();
    const phoneNumber = "+918383080860";
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div className="main_landing">
      {/* we can add background from url in below container using style  */}
      <div
        className={`other_main_bg_cover`}
        style={{
          background: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),url(${background?.replace(/ /g, '%20')})`,
        }}
      >
        <Header />
        {!isHome && (
          <div>
            <h2 className="trip_heading text-center hero__heading text-white">
              {title}
            </h2>
            <h2 className="sub__heading mt-5 text-white text-center fs-4">
              {subTitle}
            </h2>
            {extraSubTitle && (
              <h2
                className={`sub__heading mt-5 text-white text-center ${
                  windowWidth < 992 ? "fs-6" : "fs-5"
                }`}
              >
                {extraSubTitle}
              </h2>
            )}
            {btn1 && btn2 && btn3 && (
              <div className="text-center about-buttons-header">
                <button className="btn" onClick={TalkToExpert}>
                  {btn1}
                </button>
                <button className="btn" onClick={ChatWithUs}>
                  {btn2}
                </button>
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {btn3}
                </button>
              </div>
            )}
          </div>
        )}

        {!isAbout && !isContact && !isBlog && !isCareer && (
          <HeroSection
            setExpertForm={setExpertForm}
            activeTrip={activeTrip}
            title={title}
            subTitle={subTitle}
          />
        )}
      </div>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default OtherLayout;
