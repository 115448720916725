import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Slider from 'react-slick'
import '../Style/BookingPage/Booking.css'
import Footer from '../components/Footer/Footer';
import BlackHeader from '../components/Header/BlackHeader';

const BookingPage = () => {
	const [trip, setTrip] = useState(null);
	const [coupon, setCoupon] = useState('');
	const [discount, setDiscount] = useState(null);
	const [allCouponCode, setAllCouponCode] = useState('');
	const [isCouponValid, setIsCouponValid] = useState(null);
	const [width, setWidth] = useState(false);
	const [transactionId, setTransactionId] = useState(null);
	const [activeTrip, setActiveTrip] = useState(null);
	const [totalAdvancePayment, setTotalAdvancePayment] = useState(0);
	const navigate = useNavigate();
	const params = useParams();
	const arrowRef = useRef();

	// =============================== BOOKING DETAILS =============================== 
	const [date, setDate] = useState(null);
	const [person, setPerson] = useState([]);
	const [user, setUser] = useState({});
	const productDetails = person.reduce((acc, curr) => {
		const existingObj = acc.find(item => item.name === curr.name);
		if (existingObj) {
			existingObj.count += 1;
		} else {
			acc.push({ ...curr, count: 1 });
		}
		return acc;
	}, []);
	const tripId = params.id
	const GSTPercent = 5

	let totalPrice = person.reduce((sum, item) => sum + parseInt(item.price), 0);
	const GST = totalPrice * GSTPercent / 100;
	// const advancePayment = totalPrice > 12000 ? 5000 : 3000;
	const remainingAmount = (totalPrice + GST) - totalAdvancePayment - discount;
	const totalAmount = totalPrice + GST - discount;

	// =============================== BOOKING DETAILS END =============================== 

	const [toggle, setToggle] = useState(1);
	const [count, setCount] = useState(0);

	// Month & Dates for Booking
	const liveDates = activeTrip?.dates.filter((obj) => {
		return new Date(obj.date) >= new Date()
	});
	const [month, setMonth] = useState([]);
	const today = new Date();
	const currentMonthIndex = today.getMonth();

	// Taking out unique months from all the dates
	const uniqueMonths = Array.from(new Set(liveDates?.map(obj => new Date(obj.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));

	// Filtering months greater than or equal to the current month
	const bookingMonths = uniqueMonths.filter((month, index) => {
		const monthIndex = new Date(`${month} 1, 2000`).getMonth();
		return monthIndex >= currentMonthIndex || monthIndex === 0; // Include the current month
	});

	// Map each date to its corresponding month
	const dataByMonth = {};
	activeTrip && activeTrip.dates.forEach((obj) => {
		const date = new Date(obj.date);
		const month = date.toLocaleString("default", { month: "long" });
		if (!dataByMonth[month]) {
			dataByMonth[month] = [];
		}
		dataByMonth[month].push(obj.date);
	});
	// Remove duplicates from each month's array of dates
	for (const month in dataByMonth) {
		dataByMonth[month] = [...new Set(dataByMonth[month])];
	}
	function getDatesForMonth(month) {
		return dataByMonth[month];
	}

	//Date arrow slider
	const [currentIndex, setCurrentIndex] = useState(0)
	useEffect(() => {
		setMonth(getDatesForMonth(bookingMonths[currentIndex]))
		// eslint-disable-next-line
	}, [trip])

	function handlePrevious() {
		arrowRef.current.slickPrev();
		if (currentIndex > 0) {
			setMonth(getDatesForMonth(bookingMonths[currentIndex - 1]))
			setCurrentIndex(currentIndex - 1)
		}
	}
	function handleNext() {
		arrowRef.current.slickNext();
		if (currentIndex < bookingMonths.length - 1) {
			setMonth(getDatesForMonth(bookingMonths[currentIndex + 1]))
			setCurrentIndex(currentIndex + 1)
		}
	}

	// Room Sharing
	const removeCart = (elm) => {
		const itemIndex = person.findIndex(item => item.name === elm.name && item.price === elm.price);
		const filteredData = itemIndex !== -1 ? [...person.slice(0, itemIndex), ...person.slice(itemIndex + 1)] : [...person];
		setPerson(filteredData)
	}

	const addCart = (item) => {
		setPerson([...person, item])
	}

	// User Details
	const handleUser = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value })
	}

	var settings = {
		dots: false,
		infinite: false,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		prevArrow: false,
		nextArrow: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	};

	//payment part
	let details
	if (toggle === 4) {
		details = productDetails.map(({ name, count }) => `${name}: ${count}`).join(', ');
		details = date + " " + details
	}


	const addUser = () => {
		axios({
			method: "POST",
			data: {
				name: user?.username,
				number: user?.number,
				city: user?.address,
				email: user?.email,
				tripId: tripId,
				total: Math.round(totalAmount),
				remaining: Math.round(remainingAmount),
				advance: totalAdvancePayment,
				details: details,
				title: activeTrip?.heading,
				txnid: transactionId,
				tripDate: date,
				productDetails: productDetails,
			},
			withCredentials: true,
			url: `api/trips/add-new-user`,
		}).then((res) => {
		}).catch((err) => {
			console.log(err);
		});
	}

	const getTotalAdvancePayment = () => {
		let total = 0;
		person?.forEach(item => {
			const price = parseInt(item.price, 10);
			if (!isNaN(price)) {
				if (price > 12999) {
					total += 5000;
				} else {
					total += 3000;
				}
			}
		});
		return setTotalAdvancePayment(total)
	}

	useEffect(() => {
		if (toggle === 3) {
			getTotalAdvancePayment();
		}
		// eslint-disable-next-line
	}, [toggle])

	function generateTransactionID() {
		const timestamp = Date.now();
		const randomNum = Math.floor(Math.random() * 1000000);
		const merchantPrefix = 'TRIPGIX';
		const transactionID = `${merchantPrefix}${timestamp}${randomNum}`;
		return transactionID;
	}
	const handlePayment = () => {
		addUser();
		axios({
			method: "POST",
			data: {
				amount: totalAdvancePayment,
				name: user?.username,
				tripId: tripId,
				transactionID: transactionId,
				number: user?.number
			},
			withCredentials: true,
			url: `api/trips/payment`,
		}).then((res) => {
			window.location.href = res.data
		}).catch((err) => {
			console.log(err);
		});
	}

	//tentativeLead
	const tentativeLead = async () => {
		await axios({
			method: "POST",
			data: {
				...user,
				heading: activeTrip?.heading,
				duration: activeTrip?.duration,
				bookingDate: new Date(date).toLocaleString('default', { day: "numeric", month: "short", year: "numeric" })
			},
			withCredentials: true,
			url: `api/payment/add-tentative-lead`,
		}).then((res) => {
		}).catch((err) => {
			console.log(err);
		});
	}

	useEffect(() => {
		if (toggle === 4) {
			setTransactionId(generateTransactionID())
			tentativeLead();
		}
		// eslint-disable-next-line
	}, [toggle])
	//end payment part

	const AllTrips = async () => {
		await axios({
			method: "POST",
			data: {
				id: params.id
			},
			withCredentials: true,
			url: `api/trips/get-trip`,
		}).then((res) => {
			setTrip(res.data.data);
			setActiveTrip(res.data.data);
			setMonth(getDatesForMonth(bookingMonths[currentIndex]))
		}).catch((err) => {
			console.log(err);
		});
	}

	useEffect(() => {
		if (trip === null) {
			AllTrips();
		}
		// eslint-disable-next-line
	}, [])

	const dateObj = new Date(date);
	dateObj.setDate(dateObj.getDate() - 4);
	const monthName = dateObj.toLocaleString('default', { month: 'long' });
	const formattedDate = `${dateObj.getDate()} ${monthName} ${dateObj.getFullYear()}`;

	// --------------------- set coupon code -----------------------------
	const setCouponCode = (e) => {
		const data = e.target.value
		const upperCaseData = data?.toUpperCase()
		setCoupon(upperCaseData)
	}

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const getAllCouponCode = () => {
		axios({
			method: "GET",
			withCredentials: true,
			url: `api/couponCode/get-all-coupon-code`,
		}).then((res) => {
			setAllCouponCode(res.data.data)
		}).catch((err) => {
			console.log(err);
		});
	}

	const onCouponApply = () => {
		const data = allCouponCode.find((data) => {
			return data.code === coupon
		})
		if (data) {
			setIsCouponValid(true)
			setDiscount(data.price)
		} else {
			setIsCouponValid(false)
			setDiscount(null)
		}
	}
	useEffect(() => {
		getAllCouponCode()
		// eslint-disable-next-line
	}, [])
	return (
		<>
			<BlackHeader />
			<div className='mb-5 booking__page Mon_font mt-5 text-center px-lg-5 px-sm-2 p-3 py-5'>
				{/* date container  */}
				<h1 className={`mb-3 fs-sm-4 fs-lg-2 ${toggle === 1 ? "show" : "content"}`}>Please Select your Batch Dates</h1>
				<div className={`book__cont container pb-3 p-0 ${toggle === 1 ? "show" : "content"}`}>
					<div className='bg-dark p-1'>
						<Slider ref={arrowRef} {...settings}>
							{
								bookingMonths.map((item) => {
									return <div>
										<button onClick={() => setMonth(getDatesForMonth(item))} className='btn text-white fs-4'>{item}</button>
									</div>
								})
							}
						</Slider>
						<div className='booking__btn__cont'>
							<button onClick={() => handlePrevious()} className={`${currentIndex > 0 ? "disabled" : ""} arrow__left`}><i className="fa-solid fa-chevron-left"></i></button>
							<button onClick={() => handleNext()} className={`${currentIndex < uniqueMonths.length - 1 ? "disabled" : ""} arrow__right`}><i className="fa-solid fa-chevron-right"></i></button>
						</div>
					</div>

					<div className='my-5 d-flex flex-wrap justify-content-center align-items-center p-3'>
						{
							month?.map((item, index) => {
								const numberToAdd = activeTrip.itinerary?.length;
								const newEndDate = new Date(item);
								newEndDate.setDate(newEndDate.getDate() + numberToAdd - 1);
								const dateObj = new Date(item)
								const options = { day: 'numeric' };
								const formattedDate = newEndDate.toLocaleDateString('en-US', options);
								const month = newEndDate.toLocaleString('default', { month: 'short' })
								const endDate = formattedDate + ' ' + month
								const monthName = dateObj.toLocaleString('default', { month: 'short' })
								const tripDate = `${dateObj.getDate()} ${monthName}`
								return <div key={index}>
									<button onClick={() =>
										new Date(item) >= new Date() && setDate(item)
									}
										className={`me-2 my-2 text-dark date__btn ${date === item && new Date(item) >= new Date() ? "active" : "disabled"} ${new Date(item) >= new Date() ? "" : "d-none"}`}>
										{tripDate} to {endDate} {activeTrip.dateStatus.map((elm, index) => {
											const dateStatusObj = new Date(elm.date)
											const dateStatusMonthName = dateStatusObj.toLocaleString('default', { month: 'short' })
											const dateStatus = `${dateStatusObj.getDate()} ${dateStatusMonthName}`
											return (tripDate === dateStatus) && " — " + elm.status
										})}
									</button>
								</div>
							})
						}
					</div>
				</div>
				{/* date container  */}

				{/* Room Sharing container  */}
				<h1 className={`mb-3 fs-sm-4 fs-lg-2 ${toggle === 2 ? "show" : "content"}`}>Please Select your Room Sharing</h1>
				<div className={`book__cont container pb-3 p-0 ${toggle === 2 ? "show" : "content"}`}>
					<h3 className='text-white bg-dark p-3'>Room Sharing</h3>
					<div className='d-flex justify-content-center'>
						<table className="table">
							<thead>
								<tr>
									<th className='book_th' scope="col">Type</th>
									<th className='book_th' scope="col">Price (per person)</th>
									<th className='book_th' scope="col"><i className='fa fa-user'></i></th>
								</tr>
							</thead>
							<tbody>
								{
									activeTrip?.costing.map((item) => {
										return (
											<tr>
												<th className='book_th' scope="row t_data">{item.name}</th>
												<td>{item.price} <i className="fa-solid fa-indian-rupee-sign" style={{ fontSize: '13px' }}></i></td>
												<td style={{display: "flex", justifyContent: "center"}}>
													<button
														style={{ border: "0", background: "lightgrey", height: "20px", width: "20px", display: "flex", alignItems:"center", justifyContent: "center" }}
														onClick={() => {
															removeCart(item, count)
															if (person.reduce((count, elm) => elm.name === item.name ? count + 1 : count, 0) === 0) {
															}
														}}
														className={`btn ${person.reduce((count, elm) => elm.name === item.name ? count + 1 : count, 0) === 0 ? "disabled" : ""}`}>
															-
													</button>
													<span className='mx-2'>{
														person.reduce((count, elm) => elm.name === item.name ? count + 1 : count, 0)
													}</span>
													<button
														style={{ border: "0", background: "lightgrey", height: "20px", width: "20px", display: "flex", alignItems:"center", justifyContent: "center" }}
														onClick={() => {
															addCart(item, count);
															if (person.reduce((count, elm) => elm.name === item.name ? count + 1 : count, 0) !== 0) {
															}
														}}
														className='btn'
													>
														+
													</button>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{/* Room Sharing container  */}

				{/* User container  */}
				<h1 className={`mb-3 fs-sm-4 fs-lg-2 ${toggle === 3 ? "show" : "content"}`}>Please Provide your Details</h1>
				<div className={`book__cont container pb-3 p-0 ${toggle === 3 ? "show" : "content"}`}>
					<h3 className='text-white bg-dark p-3'>User Details</h3>
					<form className='p-5 d-block m-auto' style={{ maxWidth: "500px" }}>
						<div className="mb-3 text-start">
							<label htmlFor="username" className="form-label">Your Name <span className='text-danger'>*</span></label>
							<input maxLength={25} required onChange={handleUser} name='username' type="text" className="form-control" id="username" aria-describedby="username" />
						</div>
						<div className="mb-3 text-start">
							<label htmlFor="Email1" className="form-label">Email address <span className='text-danger'>*</span></label>
							<input maxLength={40} onChange={handleUser} name='email' type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
						</div>
						<div className="mb-3 text-start">
							<label htmlFor="contact" className="form-label">Contact Number <span className='text-danger'>*</span></label>
							<input maxLength={10} onChange={handleUser} name='number' type="text" className="form-control" id="contact" aria-describedby="contact" />
						</div>
						<div className="mb-3 text-start">
							<label htmlFor="city" className="form-label">City</label>
							<input maxLength={15} onChange={handleUser} name='address' type="text" className="form-control" id="address" aria-describedby="address" />
						</div>
					</form>
				</div>
				{/* User container  */}

				{/* Review Booking  */}
				<h1 className={`mb-3 fs-sm-4 fs-lg-2 ${toggle === 4 ? "show" : "content"}`}>Please check all the Details</h1>
				<div className={`book__cont container pb-3 p-0 ${toggle === 4 ? "show" : "content"}`}>
					<h3 className='text-white bg-dark p-3'>Review Booking</h3>
					<div className={`me-3 d-flex justify-content-between align-items-start w-100 ${windowWidth < 600 ? 'flex-column' : ''} coupon-code`}>
						<div className={`mx-3 text-start `}>
							<h4 className='text-start my-3'><strong>{activeTrip?.heading} ({activeTrip?.duration})</strong></h4>
							<div className='hr-line-head'>
								<div className="hr-line m-0 mb-3 "></div>
							</div>
							<p className='m-0 p-0'><i className='fa-solid fa-calendar-days'></i><small className='ms-2 fw-light'>{new Date(date).toLocaleString('default', { day: "numeric", month: "short", year: "numeric" })}</small></p>
							<p className='m-0 p-0'><i className='fa fa-user'></i><small className='ms-2 fw-light'>{user.username}</small></p>
							<p className='m-0 p-0'><i className='fa-solid fa-envelope'></i><small className='ms-2 fw-light'>{user.email}</small></p>
							<p className='m-0 p-0'><i className='fa-solid fa-phone'></i><small className='ms-2 fw-light'>{user.number}</small></p>
						</div>
						<div className={`text-start Mon_font ${windowWidth < 600 ? 'w-100' : 'w-50'} `}>
							{windowWidth < 600 && <hr />}
							<h4 className='ms-3 text-start my-3 Mon_font text-dark'><strong>Apply Coupon Code</strong></h4>
							<div className='ms-3 hr-line-head'>
								<div className="hr-line m-0 mb-3 "></div>
							</div>
							<div className='ms-3 d-flex'>
								<input type="text" value={coupon} onChange={setCouponCode} name='coupon' placeholder='COUPON CODE' className='w-50 px-2 py-3 border-1' style={{ backgroundColor: 'rgb(240, 234, 226)', borderRadius: '5px', fontSize: '17px' }} />
								<button className='btn btn-warning mx-3 px-4' onClick={onCouponApply}>Apply</button>
							</div>
							<div>
								{isCouponValid === true && <h6 className='ms-3 text-start my-3 Mon_font text-success'><i className="fa-solid fa-check p-2 bg-success text-white me-2"></i>Coupon Code Applied Successfully</h6>}
								{isCouponValid === false && <h6 className='ms-3 text-start my-3 Mon_font text-danger'><i className="fa-solid fa-warning p-2 bg-danger text-white me-2"></i>Coupon Code Not Found</h6>}
							</div>
						</div>
					</div>
					<hr />
					<h4 className='ps-3 my-3 text-start'><strong>Payment Details</strong></h4>
					<div className='p-3 pt-0'>
						<table className="table shd mb-3">
							<thead className='bg-dark text-white'>
								<tr>
									<th className='book_th' scope="col">Particulars</th>
									<th className='book_th' scope="col">Cost</th>
									<th className='book_th' scope="col">No. of persons</th>
								</tr>
							</thead>
							<tbody>
								{
									productDetails.map((item) => {
										return <tr>
											<th className='book_th' scope="row t_data">{item.name}</th>
											<td>{item.price}/-</td>
											<td>{item.price} x {item.count}</td>
										</tr>
									})
								}
								<tr>
									<th className='book_th' scope="row t_data">GST @5%</th>
									<td>{Math.round((totalPrice * 5) / 100)}/-</td>
									<td></td>
								</tr>
								{discount && <tr>
									<th className='book_th text-success' scope="row t_data">Discount</th>
									<td>-{discount}/-</td>
									<td className='text-sm'>{coupon}</td>
								</tr>}
								<tr style={{ backgroundColor: 'rgb(240, 234, 226)' }}>
									<th className='book_th' scope="row t_data">Total Cost</th>
									<th className='book_th' scope="row t_data"><strong>{(Math.round(totalPrice + GST)) - discount}/-</strong></th>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className='p-3'>
						<div className='shd px-0 p-0 pb-3'>
							<div className='text-start'>
								{/* <p className='m-0 p-0 ms-3'><small className='fw-light'>Payment Processing fee of 3% will be charged in next step</small></p> */}
								{/* <hr /> */}
								<h6 className='p-3' style={{ backgroundColor: 'rgb(240, 245, 226)' }}>Book this trip now by paying {totalAdvancePayment}/- only.</h6>
								<p className='m-0 p-0 ms-3 pt-2'><small className='fw-light'><b>Note:</b> Balance amount of <b> {Math.round(remainingAmount)}/- </b> can be paid upto {formattedDate}</small></p>
								<hr />
							</div>
							<div className='text-start mt-2'>
								<p className='m-0 p-0 text-success ms-3'><small>Don't want to Pay Payment Gateway Charges???</small></p>
								<p className='m-0 p-0 fw-light ms-3'><small>Transfer ₹{totalAdvancePayment} through the following payment methods and share the screenshot of this page and transaction on +91-8383-0808-60 on whatsapp</small></p>
								<hr />
								<p className='m-0 p-0 fw-light ms-3'><small><b><strong>UPI:</strong></b> <b>info.tripgix@icici</b></small></p>
								<hr />
								<p className='m-0 p-0 fw-light ms-3'><small><b><strong>Bank Transfer</strong></b><br />
									Bank Name: INDUSIND BANK <br />
									A/C No: 258383080860 <br />
									A/C Name: TRIPGIX VENTURES <br />
									IFSC Code: INDB0000858 <br />
									or go on next step to pay Rs {totalAdvancePayment}/-
								</small></p>
							</div>
						</div>
					</div>
				</div>
				{/* Review Booking  */}

				<div className={`${toggle === 4 ? "show" : "content"} my-3`}>
					<Link to='https://wa.me/917982328247'>
						<button className='mx-2 btn btn-success'><i className='me-2 fa-brands fa-whatsapp'></i>Whatsapp</button>
					</Link>
					<Link to='tel:+917982328247'>
						<button className='mx-2 btn btn-warning'><i className='me-2 fa-solid fa-phone'></i>Call Now</button>
					</Link>
				</div>

				<div className='d-flex justify-content-evenly mt-4 Mon_font'>
					{
						toggle === 1 ?
							<button className='btn btn-dark' onClick={() => navigate(`/trip/${(activeTrip?.heading).replace(/\s+/g, '-')}`)}>Back</button>
							:
							<button className={`btn btn-dark text-white ${toggle === 1 ? "disabled" : ""}`} onClick={() => setToggle(toggle - 1)} >Back</button>
					}
					<div>
						<i className={`mx-2 ${toggle === 1 ? "fa-sharp fa-solid fa-circle-dot" : "fa-sharp fa-regular fa-circle-dot"}`}></i>
						<i className={`mx-2 ${toggle === 2 ? "fa-sharp fa-solid fa-circle-dot" : "fa-sharp fa-regular fa-circle-dot"}`}></i>
						<i className={`mx-2 ${toggle === 3 ? "fa-sharp fa-solid fa-circle-dot" : "fa-sharp fa-regular fa-circle-dot"}`}></i>
						<i className={`mx-2 ${toggle === 4 ? "fa-sharp fa-solid fa-circle-dot" : "fa-sharp fa-regular fa-circle-dot"}`}></i>
					</div>
					{
						toggle === 1 ?
							<button className={`btn btn-dark ${date === null ? "disabled" : ""}`} onClick={() => setToggle(toggle + 1)}>Next</button>
							:
							toggle === 2 ?
								<button className={`btn btn-dark ${person.length === 0 ? "disabled" : ""}`} onClick={() => setToggle(toggle + 1)}>Next</button>
								: toggle === 3 ?
									<button className={`btn btn-dark ${!user.username || !user.email || !user.number ? "disabled" : ""}`} onClick={() => setToggle(toggle + 1)}>Next</button>
									: toggle === 4 &&
									<button type='submit' className='Mon_font btn btn-dark text-white m-0' onClick={handlePayment}>Pay</button>
					}
				</div>
			</div>
			<Footer />
		</>
	)
}

export default BookingPage
