import React, { useEffect, useState } from 'react'
import Layout from '../../components/Admin/AdminLayout';
import '../../Style/Admin/AdminDashboard.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminDashboard = () => {
    const navigate = useNavigate();
    // ===================== DASHBOARD STATES =====================
    const [sales, setSales] = useState(null);
    const [trips, setTrips] = useState(null);
    const [category, setCategory] = useState(null);
    const [inquiry, setInquiry] = useState(null);
    const [bookings, setBookings] = useState(null);
    const [currentBookings, setCurrentBookings] = useState(null);

    const AllTrips = async () => {
        await axios.get(`api/trips/get-all-trips`).then((res) => {
            setTrips(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            setCategory(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    const getAllInquiry = () => {
        axios({
            method: "get",
            withCredentials: true,
            url: `api/inquiry/get-all-inquiry`,
        }).then((res) => {
            let arr = res.data.data
            setInquiry(arr.reverse())
        }).catch((err) => {
            console.log(err);
        });
    }
    const getAllBookings = (status) => {
        axios({
            method: "get",
            withCredentials: true,
            url: `api/booking/get-all-booking`,
        }).then((res) => {
            let arr = res.data.data;
            setBookings(arr.reverse());
            let currentDate = new Date().toISOString().slice(0, 10);
            let allCurrentBookings = arr?.filter((booking)=>{
                let bookingDate = new Date(booking.details.split(' ')[0]).toISOString().slice(0, 10)
                return bookingDate >= currentDate
            })
            setCurrentBookings(allCurrentBookings);
        }).catch((err) => {
            console.log(err);
        });
    }

    // ==================== TOTAL SALES ====================
    const totalSales = bookings?.reduce((acc, obj) => acc + parseInt(obj.advance), 0);
    const [totalSale, setTotalSale] = useState(totalSales);
    useEffect(() => {
        if (trips === null) {
            AllTrips();
        }
        if (category === null) {
            getAllCategory();
        }
        if (inquiry === null) {
            getAllInquiry();
        }
        if (bookings === null) {
            getAllBookings();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (bookings && bookings.length) {
            setTotalSale(bookings?.reduce((acc, obj) => acc + parseInt(obj.advance), 0))
        }
    }, [bookings])

    return (
        <Layout>
            <div className="container-fluid m-0 p-0 Mon_font dashboard__container">
                <h2 className='p-3 bg-dark text-start text-white'>Dashboard</h2>
                <div className="row p-3">
                    <div className="col-lg-8">
                        <div className="cards">
                            
                            <div className="card" onClick={()=>{navigate('/booking-list')}}>
                                <div className="text-end"><i className="fa-solid fa-sack-dollar"></i></div>
                                <div className="amount">₹{totalSale}</div>
                                <div className="card__name">Total Sales</div>
                            </div>
                            <div className="card" onClick={()=>{navigate('/all-trips')}}>
                                <div className="text-end"><i className="fa-solid fa-plane"></i></div>
                                <div className="amount">{trips?.length}</div>
                                <div className="card__name">Total Trips</div>
                            </div>
                            <div className="card" onClick={()=>{navigate('/add-category')}}>
                                <div className="text-end"><i className="fa-solid fa-bars"></i></div>
                                <div className="amount">{category?.length}</div>
                                <div className="card__name">Total Category</div>
                            </div>
                            <div className="card" onClick={()=>{navigate('/customer-inquiry')}}>
                                <div className="text-end"><i className="fa-solid fa-phone"></i></div>
                                <div className="amount">{inquiry?.length}</div>
                                <div className="card__name">Total Inquiry</div>
                            </div>
                            <div className="card" onClick={()=>{navigate('/booking-list')}}>
                                <div className="text-end"><i className="fa-solid fa-file-pen"></i></div>
                                <div className="amount">{bookings?.length}</div>
                                <div className="card__name">Total Bookings</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 updates__container border">
                        <div className="py-3 d-flex justify-content-between align-items-center">
                            <b>Inquiry Updates</b>
                            <button className='btn btn-warning' onClick={() => navigate("/customer-inquiry")}>View All</button>
                        </div>
                        
                        <div className="container-fluid p-0">
                            <table className='table updates__table p-0 '>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Email</th>
                                    </tr>
                                </thead>
                                {
                                    inquiry?.map((item, index) => {
                                        return <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                            </tr>
                                        </tbody>
                                    }).slice(0, 5)
                                }
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3 p-3 pt-0 px-0">
                    <div className="bg-light border-bottom border-1 p-3 d-flex justify-content-between align-items-center">
                        <h4><b>Recent Orders</b></h4>
                        <button className='btn btn-warning' onClick={() => navigate("/booking-list")}>View All</button>
                    </div>
                    {currentBookings?.length === 0?
                    <div className='text-center'>
                        <p className='mt-5'>No Record Found</p>
                    </div> :
                    <div className="container">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Trip Name</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                currentBookings?.map((item, index) => {
                                    return <tbody>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item?.username}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.phone}</td>
                                            <td>{item?.title}</td>
                                            <td>
                                                <i className="fa-sharp fa-solid fa-arrow-right text-primary txt-xlg px-2 cursor-pointer"
                                                onClick={
                                                    ()=>{navigate(`/booking-trip/${item?._id}`)}
                                                }
                                            >
                                            </i>
                                            </td>
                                        </tr>
                                    </tbody>
                                }).slice(0, 5)
                            }
                        </table>
                    </div>}
                </div>
            </div>
        </Layout>
    )
}

export default AdminDashboard
