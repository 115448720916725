import axios from 'axios'
import React, { useEffect, useState } from 'react'
import OtherLayout from '../components/Layout/OtherLayout'
import PopupForm from '../components/PopupForm'
import career from '../images/LandingPage/bg/career.jpg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CareerModal from '../components/Career/CareerModal'

const Career = () => {
    const [expertForm, setExpertForm] = useState(false);
    const [allCareer, setAllCareer] = useState(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);
    const [responsibility, setResponsibility] = useState(null);
    const [requirement, setRequirement] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const params = useParams();
    
    useEffect(() => {
        if(params.title){
            document.title = `Tripgix - ${params.title}`
            const singleCareerData = allCareer?.filter((career)=>{
                return career._id === id
            })
            if(singleCareerData){
                setData(singleCareerData[0]);
                setResponsibility(singleCareerData[0]?.responsibility.split('\n'));
                setRequirement(singleCareerData[0]?.requirement.split('\n'));
            }
        }
        // eslint-disable-next-line
    }, [id]);

    const getAllCareer = async (elm) => {
        await axios.get(`api/career/get-all-career`).then((res) => {
            let arr = res.data.data
            setAllCareer(arr.reverse())
            }).catch((err) => {
                console.log(err);
            });
      }
      
      useEffect(() => {
          if (allCareer === null) {
            getAllCareer();
          } 
          // eslint-disable-next-line
      }, []);

      useEffect(() => {
          if (data === null) {
            navigate('/career')
          } 
          // eslint-disable-next-line
      }, [data]);

      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    return (
        <OtherLayout background={career} title="CAREER WITH US" extraSubTitle='Building your career is not just about reaching the top, but also enjoying the journey along the way'>
            
            {(params.title && data)? <div className="px-3 my-5 Mon_font container">
            <div className=" Mon_font px-3 m-0 py-4 shd border">
                    <div className=''>
                        <h4 className='fw-bold'><i className="fa-solid fa-arrow-left fs-5 me-2 py-1 px-1" onClick={()=>{navigate('/career')}} style={{cursor: 'pointer'}}></i>{data?.title}</h4>
                        <hr />
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h6 className='fs-6'>{data?.location}</h6>
                        <h6 className='fw-bold p-2 bg-warning rounded-4 fs-6 px-3'>{data?.time}</h6>
                    </div>
                </div>

                <div className=" Mon_font m-0 py-4 shd border px-0 mt-4">
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Role: <span className='fw-normal fs-6'>{data?.role}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Skills: <span className='fw-normal fs-6' style={{lineHeight: '2'}}>{data?.skills}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Address: <span className='fw-normal fs-6'>{data?.address}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Experience: <span className='fw-normal fs-6'>{data?.experience}</span></h5>
                        <hr />
                    </div>
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Salary: <span className='fw-normal fs-6'>{data?.salary}</span></h5>
                    </div>
                </div>
                <div className=" Mon_font px-0 m-0 py-4 shd border mt-4">
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Responsibilities</h5>
                        <hr />
                    </div>
                    {responsibility?.map((data)=>{
                        return (
                            <>
                            <div key={data}>
                                <h5 className='px-3 fw-bold'><span className='fw-normal' style={{fontSize: `${windowWidth < 554 ? '13px' : '17px'}`}}>- {data}</span></h5>
                            </div>
                            </>
                        )
                    })}
                </div>
                <div className=" Mon_font px-0 m-0 py-4 shd border mt-4 mb-4">
                    <div className=''>
                        <h5 className='px-3 fw-bold fs-6'>Requirement</h5>
                        <hr />
                    </div>
                    {requirement?.map((data)=>{
                        return (
                            <>
                            <div key={data}>
                                <h5 className='px-3 fw-bold'><span className='fw-normal' style={{fontSize: `${windowWidth < 554 ? '13px' : '17px'}`}}>- {data}</span></h5>
                            </div>
                            </>
                        )
                    })}
                </div>
                <div className='career-apply-button text-center'>
                    <button className='btn' data-bs-toggle="modal" data-bs-target="#career-modal">Apply Now</button>
                </div>
                <CareerModal careerData={data}/>
            </div>
            :
            <div>
            <div className='Mon_font container my-5'>
                <h3 className={`fw-bold text-center`}>Why work with us?</h3>
                <div className={`text-center ${windowWidth < 992 ? 'mb-4' : 'mb-4'}`}>
                    <div className="hr-line text-center"></div>
                </div>
                <div className={`d-flex gap-4 my-3 ${windowWidth < 992 ? 'flex-wrap' : null} `}>
                    <div className='d-flex mx-2'>
                        <div>
                            <i className="fa-solid fa-person-hiking p-3 px-2 text-warning" style={{fontSize: '40px'}}></i>
                        </div>
                        <div className='p-3 pb-0 px-2'>
                            <h5 className={`fw-bold ${windowWidth < 992 ? 'fs-6' : 'fs-5'}`}>Adventure and Exploration</h5>
                            <p className='mt-3 fs-6'>
                               <small>Working for a traveling company allows you to embark
                                on exciting journeys, explore new places, and experience diverse cultures firsthand.</small> 
                            </p>
                        </div>
                    </div>
                    <div className='d-flex mx-2'>
                        <div>
                            <i className="fa-solid fa-arrow-up-right-dots p-3 px-2 text-warning" style={{fontSize: '35px'}}></i>
                        </div>
                        <div className='p-3 pb-0 px-2'>
                            <h5 className={`fw-bold ${windowWidth < 992 ? 'fs-6' : 'fs-5'}`}>Adventure and Exploration</h5>
                            <p className='mt-3 fs-6'>
                               <small>Working for a traveling company allows you to embark
                                on exciting journeys, explore new places, and experience diverse cultures firsthand.</small> 
                            </p>
                        </div>
                    </div>
                    <div className='d-flex mx-2'>
                        <div>
                            <i className="fa-solid fa-network-wired p-3 px-2 text-warning" style={{fontSize: '37px'}}></i>
                        </div>
                        <div className='p-3 pb-0 px-2'>
                            <h5 className={`fw-bold ${windowWidth < 992 ? 'fs-6' : 'fs-5'}`}>Adventure and Exploration</h5>
                            <p className='mt-3 fs-6'>
                               <small>Working for a traveling company allows you to embark
                                on exciting journeys, explore new places, and experience diverse cultures firsthand.</small> 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 my-5 Mon_font container">
                <h3 className={`fw-bold text-center`}>WE ARE HIRING!</h3>
                <div className={`text-center ${windowWidth < 992 ? 'mb-4' : 'mb-4'}`}>
                    <div className="hr-line text-center"></div>
                </div>
                <h5 className={`fw-bold ${windowWidth < 992 ? 'fs-6' : 'fs-5'} text-center`}>{`(Internship Candidates can also apply)`}</h5>
                {allCareer?.length === 0? 'no record found' :
                <div className="row p-4 mt-3">
                    {
                        allCareer && allCareer?.map(career => {
                            const date = new Date(career?.createdAt)
                            const month = date.toLocaleString('default', {month:'long'})
                            const day = date.toLocaleString('default', {day:'numeric'})
                            return (
                            <>
                                <div key={career?._id} className="mb-3 col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div className="card border-0 career-card" onClick={()=>{navigate(`/career/${career.title}`); setId(career._id)}}>
                                        <div className="p-3">
                                            <p className='p-0 m-0 fw-bold fs-5'>{career.title}</p>
                                            <p className='p-0 m-0 mt-2 fs-6'>{career.location}</p>
                                        </div>
                                        <div className="card-body d-flex justify-content-between mt-4">
                                            <Link to='#' className='fw-bold text-warning'>View and Apply</Link>
                                            <p className='m-0 bg-warning d-flex align-items-center px-3' style={{fontSize: '13px', borderRadius: '12px', fontWeight: 400}}>{career.time}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                            )
                        })
                    }
                </div>}
            </div>
            </div>
            }
            {
                expertForm &&
                <PopupForm expertForm={expertForm} setExpertForm={setExpertForm} />
            }
        </OtherLayout>
    )
}

export default Career
