import React, { useEffect, useState } from 'react'
import Form from './Contact/Form'
import '../Style/PopupForm.css'

const PopupForm = ({ expertForm, setExpertForm }) => {
    return (
        <>
            <div>
                {
                    expertForm &&
                    <div className='popup__form'>
                        <Form setExpertForm={setExpertForm} />
                    </div>
                }
            </div>
        </>
    )
}

export default PopupForm
