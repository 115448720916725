import React, { useRef } from 'react'
import Slider from 'react-slick';
import '../../Style/SingleTrip/Videos.css'

const Videos = ({ trip }) => {
    const arrowRef = useRef();
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    dots: true,
                    infinite: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 3,
                    dots: true,
                    infinite: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    return (
        <>
        {   trip?.videos?.length > 0 &&
        <div className='my-5'>
            <div className="video__cont">
                {
                    trip?.videos?.length > 0 &&
                    <>
                        <h2 className='Mon_font'>Videos</h2>
                        <div className='hr-line-head'>
                            <div className="hr-line m-0 mb-3 "></div>
                        </div>
                    </>
                }
                <div className='video d-flex justify-content-center align-items-center'>
                    <iframe className='' width="300" height="165" src={trip?.videos[0].url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </div>}
        </>
    )
}

export default Videos
