import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../Style/Footer/Footer.css";
import axios from "axios";

const Footer = () => {
	const [allCategory, setAllCategory] = useState(false);

	// get all category
	const getAllCategory = async () => {
		await axios
			.get(`api/category/get-all-category`)
			.then((res) => {
				if (res.data.data) {
					setAllCategory(res.data.data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getAllCategory();
	}, []);

	return (
		<>
			<div className="footer text-white text-center">
				<div className="page__links fs-6 d-flex flex-column flex-lg-row">
					<Link className="fs-6" to="/terms-and-conditions">
						Terms & Conditions
					</Link>
					<Link className="fs-6" to="/privacy-policy">
						Privacy Policy
					</Link>
					<Link className="fs-6" to="/refund-policy">
						Refund Policy
					</Link>
					<Link className="fs-6" to="/contact">
						Contact Us
					</Link>
					<Link className="fs-6" to="/career">
						Careers
					</Link>
				</div>
				<div className="trip__locations d-flex flex-column flex-lg-row">
					{allCategory &&
						allCategory
							?.map((obj) => {
								return (
									<li key={obj._id}>
										<Link to={`/category/${obj.name.replace(/\s+/g, "-")}`}>
											{obj.name}
										</Link>
									</li>
								);
							})
							.slice(allCategory.length - 4, allCategory.length)}
				</div>
			</div>
			<div className="text-center social__media__links mt-5 mb-4">
				<Link to="https://www.instagram.com/trip.gix/">
					<i className="fa-brands fa-instagram"></i>
				</Link>
				<Link to="https://api.whatsapp.com/send?phone=917982328247&text=Hey%20Tripgix%2C%20I%20was%20exploring%20your%20website%20and%20i%20had%20some%20queries.">
					<i className="fa-brands fa-whatsapp"></i>
				</Link>
				<Link to="https://youtube.com/@tripgix?si=rB7KCzxx2DqtVai-">
					<i className="fa-brands fa-youtube"></i>
				</Link>
				<Link to="https://www.linkedin.com/company/tripgix/">
					<i className="fa-brands fa-linkedin"></i>
				</Link>
			</div>
			<div className="Mon_font text-center footer__address">
				<h6>ADDRESS</h6>
				<p style={{marginBottom: "0px"}}>Tripgix Ventures</p>
				<p>L-53 Basement Kalkaji, New delhi 110019</p>
				<div className="email__number__website">
					<p>info.tripgix@gmail.com</p>
					<p>8383-0808-80 | 798-232-8247</p>
					<p>www.tripgix.com</p>
				</div>
			</div>
			<div className="text-center copyright__text mt-3">
				<h5>© Copyright by Tripgix Ventures</h5>
			</div>
		</>
	);
};

export default Footer;
