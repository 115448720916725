import React, { useEffect, useState } from 'react'
import '../Style/LandingPage/LandingPage.css';
import Explore from '../components/LandingPage/Explore';
import TripDetails from '../components/TripDetails/TripDetails';
import Layout from '../components/Layout/HomeLayout';
import Testmonials from '../components/LandingPage/Testmonials';
import TalkToExpert from '../components/LandingPage/TalkToExpert';
import PromotionBanner from '../components/LandingPage/PromotionBanner';
import TrendingLocation from '../components/LandingPage/TrendingLocation';
import PopularDestination from '../components/LandingPage/PopularDestination';
import PopupForm from '../components/PopupForm';
import OfferAndUpdates from '../components/LandingPage/OfferAndUpdates';
import TimerForm from '../components/TimerForm';
import axios from 'axios';
import WhyTrustUS from '../components/LandingPage/WhyTrustUS';
import DemoSlider from '../components/TripDetails/DemoSlider';
import Sitemap from '../components/SiteMap';


const LandingPage = () => {
    const [trip, setTrip] = useState(null)
    const [sliders, setSliders] = useState(null);
    const [formLoad, setFormLoad] = useState(false);

    const getHomePageSliders = () => {
        axios.get(`api/homecategory/get-home-category`).then((res) => {
            setSliders(res.data?.data[0]?.top5HomeCategory)
        })
    }
    // get All trips
    const AllTrips = async () => {
        await axios.get(`api/trips/get-all-trips`).then((res) => {
            let currentDate = new Date().toISOString().slice(0, 10);
            const currentTrips = res.data.data?.filter(trip => {
                return trip.dates?.some(date => date?.date >= currentDate);
            });
            setTrip(currentTrips)
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        const isVisited = localStorage.getItem('isVisited');
        if (!isVisited) {
            setTimeout(() => {
                setFormLoad(true);
            }, 15000);
            localStorage.setItem('isVisited', 'true');
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleBeforeUnload = () => {
        localStorage.removeItem('isVisited');
    };

    useEffect(() => {
        if (trip === null) {
            AllTrips();
        }
        getHomePageSliders();
    }, [])

    return (
        <Layout trip={trip} title="Escape the" subTitle="Create beautiful stories with Us..">
            <div className='container'>
                {
                    formLoad && <TimerForm formLoad={formLoad} setFormLoad={setFormLoad} />
                }
            </div>
            <div className="explore__category text-center">
                <Explore />
            </div>

            {/* ============================== SLIDERS ============================== */}
            {
                sliders?.map((item, index) => {
                    const data = trip?.filter(trip => trip?.category?.includes((item)))
                    return data?.length > 0 && <div key={index} className="">
                        <TripDetails data={data} title={(item)} category={(item)} />
                    </div>
                }).slice(0, 1)
            }

            <PromotionBanner />

            {
                sliders?.map((item, index) => {
                    const data = trip?.filter(trip => trip.category?.includes((item)))
                    return data?.length > 0 && <div className="my-4">
                        <TripDetails data={data} title={(item)} category={(item)} />
                    </div>
                }).slice(1, 10)
            }
            <div className='p-lg-5 pt-lg-0 mb-4 '>
                <TrendingLocation />
            </div>

            <TalkToExpert />

            <PopularDestination />

            <WhyTrustUS />

            <Testmonials />

            <OfferAndUpdates />

            <PopupForm />

            <DemoSlider />

        </Layout>
    )
}

export default LandingPage