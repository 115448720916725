import React, { useEffect, useState } from 'react';
import Layout from '../../components/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import { message } from 'antd';
import axios from 'axios';

const Banner = () => {
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState(null);
    const [data, setData] = useState(null);
    const [uniqueCity, setUniqueCity] = useState(null);
    const [category, setAllCategory] = useState(null);

    //get All trip for unique location
    // const getAllTrips = async () => {
    //     await axios.get(`api/trips/get-all-trips`).then((res) => {
    //         let locations = []
    //         if (res.data.data) {
    //             res.data?.data?.forEach(trip => {
    //                 locations.push(trip?.location);
    //             });
    //         }
    //         setUniqueCity([...new Set(locations)])

    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }

    // get all category
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            let categories = []
            if(res.data.data){
                res.data?.data.forEach(category => {
                    categories.push(category.name);
                });
            }
            setAllCategory(categories);
            
            }).catch((err) => {
                console.log(err);
            });
    }

    useEffect(()=>{
        getAllCategory()
        getAllBanner()
        // eslint-disable-next-line
    }, [])


    // banner image cloudinary API
    const postDetails = (image) => {
        if (!image) {
            return message.error('Please Select an image');
        }
        if (image.type === 'image/jpeg' || image.type === 'image/png') {
            const data = new FormData();
            data.append('file', image);
            data.append('upload_preset', 'onlinelecture');
            data.append('cloud_name', 'Krantikaari');
            setLoading(true)
            fetch('https://api.cloudinary.com/v1_1/dn5oapayl/image/upload/', {
                method: 'post',
                body: data,
            }).then((res) => res.json()).then((data) => {
                setLoading(false)
                setInputFields({ ...inputFields, image: data.secure_url })
            }).catch((err) => {
                message.error(err);
            })
        } else {
            return message.error('Please Select an image');
        }
    };

    // on form value change
    const handleChange = (event, value) => {
        if (value === 'bannerImage') {
            postDetails(event.target.files[0])
        }
        setInputFields({ ...inputFields, [event.target.name]: event.target.value })
    }

    //on form submit
    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true)
        axios({
            method: "POST",
            data: {
                location: inputFields.location,
                image: inputFields.image,
            },
            withCredentials: true,
            url: `api/banners/add-banner`,
        }).then((res) => {
            getAllBanner()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }

    // on banner delete
    const deleteBanner = (id) => {
        axios({
            method: "POST",
            data: {
                id: id,
            },
            withCredentials: true,
            url: `api/banners/delete-banner`,
        }).then((res) => {
            getAllBanner()
            message.success(res.data.message)
        }).catch((err) => {
            console.log(err);
        });
    }

    const getAllBanner = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `api/banners/get-all-banner`,
        }).then((res) => {
            setData(res.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Layout>
            <AdminHeader title='Promotional Banner' redirect='/admin-dashboard' btnName='Add City Image' btnUrl='/add-city-image' btnName2='Add Coupon Code' btnUrl2='/add-coupon-code'/>
            <div className="container Mon_font px-4">
                <form className="" style={{ backgroundColor: "#fff" }} onSubmit={submitHandler}>
                    <div className="col-3 my-3">
                        <label htmlFor="location" className="form-label">Select Location</label>
                        <select className="form-select" value={inputFields?.location} required name="location" onChange={(event) => { handleChange(event) }}>
                            <option value="">Select</option>
                            {category && category.map((category) => {
                                return (
                                    <option value={category}>{category}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-3 my-3">
                        <label htmlFor="tripImage" className="form-label">Add Banner</label>
                        <input required type="file" accept="image/*" className="form-control" id="tripImage" onChange={(event) => { handleChange(event, 'bannerImage') }} />
                        <span className='text-secondary'><small>Banner size - 1350x375px</small></span>
                        {inputFields?.image && <div className="col-4 my-2">
                            <img width={340} src={inputFields.image} alt="Loading" />
                        </div>}
                    </div>
                    <hr />
                    <div className='my-2'>
                        <button disabled={loading === true} type='submit' className='btn btn-warning w-100 '>{loading ? 'Loading...' : 'Submit'}</button>
                    </div>
                </form>
            </div>

            {/* all banners */}
            <div className="container Mon_font px-4 pb-4">
                <table className='table table-light table-bordered w-100 m-0 mt-4'>
                    <thead className='p-2 py-3 table-secondary'>
                        <tr>
                            <th scope="col" className='py-2'>Banner</th>
                            <th scope="col" className='py-2'>Location</th>
                            <th scope="col" className='py-2'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((data) => {
                            return <tr className='m-2' key={data._id}>
                                <td className=''>
                                    <img className='m-2' width={300} src={data.image} alt="Loading" />
                                </td>
                                <td className='text-danger'>{data.location}</td>
                                <td className='ps-4'>
                                    <div className="flex gap-2">
                                        <i className="fa-sharp fa-solid fa-trash cursor-pointer p-2" onClick={() => deleteBanner(data._id)}></i>
                                    </div>
                                </td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

export default Banner
