import React, { useEffect, useState } from 'react'
import timerImage from '../images/LandingPage/timerImage.jpg'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { message } from 'antd'

const TimerForm = ({ setFormLoad }) => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        setFormLoad(false)
        axios({
            method: "POST",
            data: {
                name: form.name,
                phone: form.phone,
                email: form.email,
                message: form.message,
            },
            withCredentials: true,
            url: `api/inquiry/add-inquiry`,
        }).then((res) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            if (res.data.success) {
                message.success(res.data.message)
            }
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }


    return (
        <div className='container timer_form_cont'>
            <div className='text-end px-4'>
                <CloseIcon className='cursor-pointer f20' onClick={() => setFormLoad(false)} />
            </div>
            <div className="row Mon_font">
                <div className="timer-img-container d-flex justify-content-center align-items-center col-12 py-4 col-sm-12 col-md-6 col-lg-6">
                    <img className='timerform__img' src={timerImage} alt="" />
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-6">
                    <form className='timerForm' onSubmit={handleSubmit}>
                        <h3 className='text-center'>Plan Your Next Trip</h3>
                        <div className="row">
                            <div className="mb-3 col-6 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input required placeholder='Enter name' name='name' onChange={handleChange} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3 col-6 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor="name" className="form-label">Phone</label>
                                <input required placeholder='Enter number' name='phone' onChange={handleChange} type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="name" className="form-label">Email</label>
                                <input required placeholder='Enter email' name='email' onChange={handleChange} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3 col-12">
                                <label htmlFor="name" className="form-label">Message</label>
                                <input required placeholder='Enter message' name='message' onChange={handleChange} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <button type='submit' className='text-center'>{loading ? 'Loading...' : 'Submit'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TimerForm