import React, { useEffect, useState } from 'react';
import job from '../../images/LandingPage/bg/job.png';
import axios from 'axios';
import { message } from 'antd';

const CareerModal = ({careerData}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [resume, setResume] = useState(null);
    const [loading, setLoading] = useState(null);
    const [inputField, setInputField] = useState({
        name: '',
        number: '',
        email: '',
        position: careerData?.title,
        experience: '',
        jobType: '',
        jobDes: '',
    });
    
    const onHandleInputChange = (e)=>{
        setInputField({...inputField, [e.target.name]: e.target.value})
    }

    const onSetResume = (e)=>{
        setResume(e.target.files[0])
    }

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const onCareerApply = ()=>{
        const form = document.getElementById('career-form');
        form.reportValidity();
        
        if(form.reportValidity() === true){
            setLoading(true);
            const formData = new FormData();
            formData.append('name', inputField.name);
            formData.append('jobId', careerData?._id);
            formData.append('number', inputField.number);
            formData.append('email', inputField.email);
            formData.append('position', careerData?.title);
            formData.append('experience', inputField.experience);
            formData.append('jobType', inputField.jobType);
            formData.append('jobDes', inputField.jobDes);
            formData.append('resume', resume);
            axios({
                method: "POST",
                data: formData,
                withCredentials: true,
                url: `api/career/add-job`,
            }).then((res) => {
                setInputField({
                    name: '',
                    number: '',
                    email: '',
                    position: careerData?.title,
                    experience: '',
                    jobType: '',
                    jobDes: '',
                    resume: null,
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800);
                alert('Your Application Has Been Submitted')
            }).catch((err) => {
                setTimeout(() => {
                    setLoading(false)
                }, 1500);
                message.error(err.response.data.message)
                console.log(err);
            });
        }
    }

  return (
    <div>
        <div className="modal fade" id="career-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-4 fw-bold" id="exampleModalLabel">{careerData?.title}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className={`modal-body p-0 ${windowWidth < 800 ? 'py-3' : 'd-flex'}`}>
                    <div className={`w-100  ${windowWidth < 800 ? 'd-none' : null} d-flex justify-content-center align-items-center`}>
                        <img width={500} src={job} alt="" />
                    </div>
                    <form action="" className={`m-0 w-100 row p-0 gap-3 p-3`} id='career-form'>
                        <div className={`col-7 border border-1 p-3 m-0 position-relative career-apply-input-div-height w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Full Name</span>
                                <input value={inputField.name} className='p-0 m-0 border-0 rounded-0 w-100' type="text" name='name' required onChange={onHandleInputChange}/>
                            </div>
                        </div>
                        <div className={`col-4 border border-1 p-3 position-relative career-apply-input-div-height w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Number</span>
                                <input value={inputField.number} className='p-0 m-0 border-0 rounded-0 w-100' type="text" name='number' minLength={10} required maxLength={10} onChange={onHandleInputChange}/>
                            </div>
                        </div>
                        <div className={`col-7 border border-1 p-0 p-3 position-relative career-apply-input-div-height w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Applying for the position</span>
                                <input className='p-0 m-0 border-0 rounded-0 w-100' value={careerData?.title} name='position' placeholder={careerData?.title}  type="text" disabled  required onChange={onHandleInputChange}/>
                            </div>
                        </div>  
                        <div className={`col-4 border border-1 p-3 position-relative career-apply-input-div-height w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Email</span>
                                <input value={inputField.email} className='p-0 m-0 border-0 rounded-0 w-100' type="email" name='email' required onChange={onHandleInputChange}/>
                            </div>
                        </div>
                        <div className={`col-7 border border-1 p-3 position-relative career-apply-input-div-height w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Experience</span>
                                <select value={inputField.experience} className="p-0 bg-white text-dark m-0 border-0 rounded-0 w-100" name='experience'   type="text"  required onChange={onHandleInputChange}>
                                    <option value=''>Select Experience</option>
                                    <option value="Fresher">Fresher</option>
                                    <option value="1-2 Years">1-2 Years</option>
                                    <option value="3-4 Years">3-4 Years</option>
                                    <option value="5+ Years">5+ Years</option>
                                </select>
                            </div>
                        </div>  
                        <div className={`col-4 border border-1 p-3 position-relative career-apply-input-div-height w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Applying for</span>
                                <select value={inputField.jobType} className="p-0 bg-white text-dark m-0 border-0 rounded-0 w-100" name='jobType'   type="text"  required onChange={onHandleInputChange}>
                                    <option value=''>Select Job Type</option>
                                    <option value="Full Time">Full Time</option>
                                    <option value="Internships">Internships</option>
                                </select>
                            </div>
                        </div>  
                        <div className={`col-9 border border-1 p-3 py-2 position-relative w-100`}>
                            <div className='p-0 m-0 career-apply-form'>
                                <span className='career-apply-form-lable'>Attach Resume/CV</span>
                                <input accept='.pdf' className="p-0 mt-2 bg-white text-dark m-0 border-0 rounded-0 w-100" name='resume' type="file"  required onChange={onSetResume}/>
                            </div>
                        </div>
                        <div className={`col-9 border border-1 p-3 py-2 position-relative w-100 `}>
                            <div className='p-0 m-0 career-apply-form mb-3'>
                                <span className='career-apply-form-lable'>What makes you the right person for this job?</span>
                                <textarea value={inputField.jobDes} className="p-0 bg-white text-dark m-0 border-0 rounded-0 w-100" name='jobDes' rows="3" style={{resize: 'none'}}  type="text"  required onChange={onHandleInputChange}/>
                            </div>
                        </div>
                        <button type="submit" id="submit-career-apply-button" className='d-none'></button>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center career-apply-button">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" id='submit-career-apply-outside-button' className="btn btn-warning" onClick={onCareerApply}>{loading? 'loading..' : 'Submit'}</button>
                </div>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CareerModal
