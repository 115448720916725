import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import BaliYoga from '../ItneraryPdf/BaliYoga.pdf'
// import BaliYogaDavid from '../ItneraryPdf/BaliYogaDavid.pdf'
import '../../Style/SingleTrip/DateAndCosting.css'

const Costing = ({ data }) => {
    const navigate = useNavigate();
    const [month, setMonth] = useState([]);
    console.log(month)
    // console.log(new Date(month).toLocaleString('default', { month: 'long' }))
    const liveDates = data?.dates.filter((obj) => {
        return new Date(obj.date) >= new Date()
    });

    // taking out unique months from all the dates
    const uniqueMonths = Array.from(new Set(liveDates?.map(obj => new Date(obj.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));

    // Map each date to its corresponding month
    const dataByMonth = {};
    data?.dates.forEach((obj) => {
        const date = new Date(obj.date);
        const month = date.toLocaleString("default", { month: "long" });
        if (!dataByMonth[month]) {
            dataByMonth[month] = [];
        }
        dataByMonth[month].push(obj.date);
    });

    // Remove duplicates from each month's array of dates
    for (const month in dataByMonth) {
        dataByMonth[month] = [...new Set(dataByMonth[month])];
    }

    function getDatesForMonth(month) {
        return dataByMonth[month];
    }
    const bookingHandler = (id) => {
        navigate(`/book/${id}`)
    }

    // Remaining Future Months after matching current months 
    const today = new Date();
    const currentMonthIndex = today.getMonth();
    const bookingMonths = uniqueMonths.filter((month, index) => {
        return new Date(`${month} 1,2000`).getMonth() >= currentMonthIndex;
    })
    return (
        <>
            {/* ================================ COSTING ================================ */}
            <div className="shd sidebar-costing">
                {
                    data?.costing?.length > 0 &&
                    <div className=''>
                        <h2 className='Mon_font text-center'>Package Cost</h2>
                        <div className="hr-line mb-3 text-center"></div>
                    </div>
                }
                <table className="table costing__table mb-5">
                    {
                        data?.costing?.length > 0 &&
                        <thead className="table-dark border Mon_font" style={{ borderRadius: "15px" }}>
                            <tr>
                                <th scope="" className='text-center'><strong>Room Sharing</strong></th>
                                <th scope="" className='text-center'><strong>Cost (per person)</strong></th>
                            </tr>
                        </thead>}
                    <tbody className='bg-light border'>
                        {
                            data?.costing?.map((item) => {
                                return (
                                    <tr>
                                        <td className='Mon_font text-center'><strong>{item.name}</strong></td>
                                        <td className='text-center text-lg-center text-md-center border'><strong>{item.price}</strong>/-</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* ================================ COSTING END ================================ */}
        </>

    )
}

export default Costing
