import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Style/SingleTrip/DateAndCosting.css'

const DatesAndCosting = ({ data, itinerary }) => {
    const navigate = useNavigate();
    const dataByMonth = {};
    const liveDates = data?.dates.filter((obj) => {
        return new Date(obj.date) >= new Date()
    });
    
    // Remaining Future Months after matching current months
    const today = new Date();
    const currentMonthIndex = today.getMonth();

    // Taking out unique months from all the dates
    const uniqueMonths = Array.from(new Set(liveDates?.map(obj => new Date(obj.date).toLocaleString('default', { month: 'long' })))).sort((a, b) => new Date(Date.parse('01 ' + a + ' 2000')) - new Date(Date.parse('01 ' + b + ' 2000')));

    // Filtering months greater than or equal to the current month
    const bookingMonths = uniqueMonths.filter((month, index) => {
        const monthIndex = new Date(`${month} 1, 2000`).getMonth();
        return monthIndex >= currentMonthIndex || monthIndex === 0; // Include the current month
    });

    const [month, setMonth] = useState([]);

    // Map each date to its corresponding month 
    data?.dates.forEach((obj) => {
        const date = new Date(obj.date);
        const month = date.toLocaleString("default", { month: "long" });
        if (!dataByMonth[month]) {
            dataByMonth[month] = [];
        }
        dataByMonth[month].push(obj.date);
    });

    // Remove duplicates from each month's array of dates
    for (const month in dataByMonth) {
        dataByMonth[month] = [...new Set(dataByMonth[month])];
    }

    function getDatesForMonth(month) {
        return dataByMonth[month];
    }
    const bookingHandler = (id) => {
        navigate(`/book/${id}`)
    }

    return (
        <>
            {/* ================================ DATES ================================ */}
            {
                data?.customised === "true" ? "" :
                    <div className='mb-5'>
                        <div className="shd">

                            {
                                bookingMonths.length > 0 &&
                                <div className='mb-4'>
                                    <h2 className='Mon_font'>Dates</h2>
                                    <div className="hr-line mb-3 m-0"></div>
                                </div>
                            }
                            <div className='d-flex avail__months'>
                                {
                                    //MONTHS
                                    bookingMonths.map((item, index) => (
                                        <div key={index} className={`Mon_font circle`}>
                                            <button className='btn' onClick={() => setMonth(getDatesForMonth(item))}>{item.slice(0, 3)}</button>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className='avail__dates Mon_font my-4 row'>
                                <div className='d-flex flex-wrap'>
                                    {
                                        month?.length !== 0 ?
                                        month?.map((item, index) => {
                                            const numberToAdd = itinerary?.length;
                                            const date = new Date(item);
                                            date.setDate(date.getDate() + numberToAdd-1);
                                            const options = { day: 'numeric', month: 'short' };
                                            const formattedDate = date.toLocaleDateString('en-US', options);
                                            const tripDate = new Date(item) >= new Date() ? new Date(item).toLocaleDateString('default', { day: 'numeric', month: 'short' }) : ``
                                            return <div key={index}>
                                                <p className={`btn__date my-2 p-2 rounded pointer ${new Date(item) >= new Date() ? "" : "d-none"}`}>
                                                    {tripDate} to {formattedDate} {data.dateStatus.map((elm, index)=>{
                                                        const dateStatus =  new Date(elm.date).toLocaleDateString('default', { day: 'numeric', month: 'short' })
                                                        return (tripDate === dateStatus) && " — " + elm.status
                                                    })}
                                                </p>
                                            </div>
                                        })
                                        :
                                        <>
                                        {
                                            getDatesForMonth(bookingMonths[0])?.map((item, index) => {
                                                const numberToAdd = itinerary?.length;
                                                const date = new Date(item);
                                                date.setDate(date.getDate() + numberToAdd-1);
                                                const options = { day: 'numeric', month: 'short' };
                                                const formattedDate = date.toLocaleDateString('en-US', options);
                                                const tripDate = new Date(item) >= new Date() ? new Date(item).toLocaleDateString('default', { day: 'numeric', month: 'short' }) : ``
                                                return <div key={index}>
                                                    <p className={`btn__date my-2 p-2 rounded pointer ${new Date(item) >= new Date() ? "" : "d-none"}`}>
                                                        {tripDate} to {formattedDate} {data.dateStatus.map((elm, index)=>{
                                                            const dateStatus =  new Date(elm.date).toLocaleDateString('default', { day: 'numeric', month: 'short' })
                                                            return (tripDate === dateStatus) && " — " + elm.status
                                                        })}
                                                    </p>
                                                </div>
                                            })
                                        }
                                        </> 
                                    }
                                </div>
                            </div>

                            {
                                bookingMonths.length > 0 ?
                                    <div className="book__now__button d-flex">
                                        <button onClick={() => bookingHandler(data?._id)}>Book Now</button>
                                    </div>
                                    :
                                    <h3 className='Mon_font'>No Dates Available!</h3>
                            }
                        </div>
                    </div>
            }

            {/* ================================ DATES END ================================ */}

            {/* ================================ COSTING ================================ */}
            <div className="shd">
                {
                    data?.costing?.length > 0 &&
                    <div className=''>
                        <h2 className='Mon_font'>Costing</h2>
                        <div className="hr-line mb-3 m-0"></div>
                    </div>
                }
                <table className="table costing__table mb-3">
                    {
                        data?.costing?.length > 0 &&
                        <thead className="table-dark border Mon_font" style={{ borderRadius: "15px" }}>
                            <tr>
                                <th scope="" className='text-center'><strong>Room Sharing</strong></th>
                                <th scope="" className='text-center'><strong>Cost (per person)</strong></th>
                            </tr>
                        </thead>}
                    <tbody className='bg-light border'>
                        {
                            data?.costing?.map((item) => {
                                return (
                                    <tr>
                                        <td className='Mon_font text-center'><strong>{item.name}</strong></td>
                                        <td className='text-center text-lg-center text-md-center border'><strong>{item.price}</strong>/-</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* ================================ COSTING END ================================ */}
        </>

    )
}

export default DatesAndCosting
