import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaExclamationCircle } from 'react-icons/fa';

const Failure = () => {
  const params  = useParams();
  const data = params.id.split('_');
  console.log(data)

  //to upper case function
  const toUpperCase = (string) =>{
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const sendMessageToWhatsApp = ()=>{
    const message = encodeURIComponent(`Hello Tripgix, My name is *${toUpperCase(data[1])}* and I am trying to pay for a trip for *${toUpperCase(data[2])}* but the payment failed. Help me to book offline`);
    const phoneNumber = '+917982328247'; // Replace with the phone number you want to send the message to
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(whatsappLink, '_blank');
  }
  return (
    <div className='d-flex flex-column align-items-center justify-content-center Mon_font mt-2'> 
      {data && 
      <>
      <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className='my-1 pt-2 text-center'>
              <div className="payment-failed-icon">
                  <FaExclamationCircle />
              </div>
              <h3 className='m-0 text-danger'>Payment failed!</h3>
              <hr className='m-0 my-2'/>
          </div>
          <div className="w-100 px-3 text-center">
              <p className='w-50 txt-lg w-100 m-0'><strong>Dont't worry your money is safe!</strong> If your money was debited from your account, it will be refunded automatically in 5-7 working days.</p>       
          </div>
          <div className="w-100 px-3 mb-2 d-flex flex-wrap justify-content-center align-items-center">
          <table className='w-100 table border mt-4'>
              <tbody>
                  <tr>
                      <td className='txt-lg'><strong>Txn Id</strong></td>
                      <td className='txt-lg'>{data[0]}</td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div className="w-100 px-3 text-center mb-2 py-2">
            <p className='w-50 txt-lg w-100 m-0 text-danger'>Your booking has failed! Kindly contact us via Whatsapp. One of our team members  will be happy to help you.</p>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center m-0'>
            <button className='btn btn-success m-0'  onClick={sendMessageToWhatsApp}><i className="fa-brands fa-whatsapp me-2"></i>Contact Whatsapp</button>
            <p className='m-0 py-2'>or</p>
            <Link className='m-0 txt-sm' to={`/book/${data[3]}`}>
                <i className="fa-solid fa-arrow-left pe-2"></i>
                Go back to booking page
            </Link>
        </div>
      </div>
      </>
      }
    </div>
  )
}

export default Failure