import React, { useEffect, useState } from 'react'
import '../../Style/LandingPage/PopularDestination.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

const PopularDestination = () => {
    const navigate = useNavigate();

    const [popularDestination, setPopularDestination] = useState(null)
    const [allCategory, setAllCategory] = useState([])
    // get All Selected Home Page Category
    const getSelectedHomeCategory = () => {
        axios.get(`api/homecategory/get-home-category`).then((res) => {
            setPopularDestination(res.data.data[0]?.popularCategory)
        })
    }

    // get all category
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            if (res.data.data) {
                setAllCategory(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const image1 = allCategory?.find(obj => obj?.name === (popularDestination && popularDestination[0]));
    const image2 = allCategory?.find(obj => obj?.name === (popularDestination && popularDestination[1]));
    const image3 = allCategory?.find(obj => obj?.name === (popularDestination && popularDestination[2]));
    const image4 = allCategory?.find(obj => obj?.name === (popularDestination && popularDestination[3]));
    const image5 = allCategory?.find(obj => obj?.name === (popularDestination && popularDestination[4]));

    useEffect(() => {
        getSelectedHomeCategory();
        getAllCategory();
        // eslint-disable-next-line
    }, [])

    const onClick = (value)=>{
        return navigate(`/category/${popularDestination && popularDestination[value].replace(/\s+/g, '-')}`)
    }

    return (
        popularDestination &&
        <div className='Mon_font container my-5 customised-container d-block m-auto text-center'>
            <h2 className='fw-bolder m-0'>Customize Your Trip</h2>
            <div className='hr-line mb-3'></div>
            <p>Your Dates + Your Group + Your Budget</p>
            <div className="customised">
                <div className="item-30" onClick={() => onClick(0)} style={{ background: `url(${image1?.url})` }}>
                    <div className="text-center text-white mb-2 fw-bold">
                        <h5>{popularDestination && popularDestination[0]}</h5>
                    </div>
                </div>
                <div className="item-30" onClick={() => onClick(1)} style={{ background: `url(${image2?.url})` }}>
                    <div className="text-center text-white mb-2 fw-bold">
                        <h5>{popularDestination && popularDestination[1]}</h5>
                    </div>
                </div>
                <div className="item-30" onClick={() => onClick(2)} style={{ background: `url(${image3?.url})` }}>
                    <div className="text-center text-white mb-2 fw-bold">
                        <h5>{popularDestination && popularDestination[2]}</h5>
                    </div>
                </div>
                <div className="item-30" onClick={() => onClick(3)} style={{ background: `url(${image4?.url})` }}>
                    <div className="text-center text-white mb-2 fw-bold">
                        <h5>{popularDestination && popularDestination[3]}</h5>
                    </div>
                </div>
                <div className="item-70" onClick={() => onClick(4)} style={{ background: `url(${image5?.url})` }}>
                    <div className="text-center text-white mb-2 fw-bold">
                        <h5>{popularDestination && popularDestination[4]}</h5>
                    </div>
                </div>
            </div>

            {/* <div className="row d-flex justify-content-center">
                {image1 && <div className="img__cont mx-2 p-2 col-7 col-sm-7 col-md-8 col-lg-3" onClick={()=>navigate(`/customised/${popularDestination && popularDestination[1]}`)} style={{ background: `url(${image1?.url})`, padding: "10px" }}>
                    <Link to={`/category/${popularDestination && popularDestination[0]}`}>
                        <div className="img__cont__overlay"></div>
                        <h6>{popularDestination && popularDestination[0]}</h6>
                    </Link>
                </div>}

                {image2 && <div className="img__cont mx-2 p-2 col-4 col-sm-4 col-md-4 col-lg-3" onClick={()=>navigate(`/customised/${popularDestination && popularDestination[1]}`)} style={{ background: `url(${image2?.url})` }}>
                    <Link to={`/customised/${popularDestination && popularDestination[1]}`}>
                        <div className="img__cont__overlay"></div>
                        <h6>{popularDestination && popularDestination[1]}</h6>
                    </Link>
                </div>}

                {image6 && <div className="img__cont mx-2 p-2 col-4 col-sm-4 col-md-4 col-lg-3 d-none d-md-none d-lg-block" onClick={()=>navigate(`/customised/${popularDestination && popularDestination[1]}`)} style={{ background: `url(${image6?.url})` }}>
                    <Link to={`/customised/${popularDestination && popularDestination[2]}`}>
                        <div className="img__cont__overlay"></div>
                        <h6>{popularDestination && popularDestination[5]}</h6>
                    </Link>
                </div>}
            </div>

            <div className="row d-flex justify-content-center">
                {image3 && <div className="img__cont equal__img mx-2 p-2 col-3 col-sm-3 col-md-4 col-lg-3" onClick={()=>navigate(`/customised/${popularDestination && popularDestination[1]}`)} style={{ background: `url(${image3?.url})` }}>
                    <Link to={`/customised/${popularDestination && popularDestination[2]}`}>
                        <div className="img__cont__overlay"></div>
                        <h6>{popularDestination && popularDestination[2]}</h6>
                    </Link>
                </div>}

                {image4 && <div className="img__cont equal__img mx-2 p-2 col-3 col-sm-3 col-md-4 col-lg-3" onClick={()=>navigate(`/customised/${popularDestination && popularDestination[1]}`)} style={{ background: `url(${image4?.url})` }}>
                    <Link to={`/customised/${popularDestination && popularDestination[3]}`}>
                        <div className="img__cont__overlay"></div>
                        <h6>{popularDestination && popularDestination[3]}</h6>
                    </Link>
                </div>}

                {image5 && <div className="img__cont equal__img mx-2 p-2 col-3 col-sm-3 col-md-4 col-lg-3" onClick={()=>navigate(`/customised/${popularDestination && popularDestination[1]}`)} style={{ background: `url(${image5?.url})` }}>
                    <Link to={`/customised/${popularDestination && popularDestination[4]}`}>
                        <div className="img__cont__overlay"></div>
                        <h6>{popularDestination && popularDestination[4]}</h6>
                    </Link>
                </div>}
            </div> */}
        </div>
    )
}

export default PopularDestination
