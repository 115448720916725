import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const PaymentPage = () => {
    const navigate = useNavigate()
    const [hashKey, setHashKey] = useState(null)  
    const [transactionId, setTransactionId] = useState(null)

  const getHash = async()=>{
    const response = await axios.get( `api/payment/get-hashkey`).then((res) => {
      setHashKey(res.data.data);
      setTransactionId(res.data.id)
      console.log(res.data.data)
      console.log(res.data.id)
    }).catch((err) => { 
      console.log(err);
    });
  }
  useEffect(()=>{
    if(transactionId === null){
      getHash()
    }
    // eslint-disable-next-line
  })

  return (
    <>
    <div className='container my-5 Mon_font w-50'>
          <h3 className='text-center fw-bold my-3 fs-3'>Payment Information</h3>
          <hr />
          <form action="https://secure.payu.in/_payment" method="POST">
            <input type="hidden" name="key" value="" />
            <input type="hidden" name="salt" value="" />
            <input type="hidden" name="txnid" value={transactionId} />
            <input type="hidden" name="amount" value="2" />
            <input type="hidden" name="productinfo" value="ladak trip" />
            <input type="hidden" name="firstname" value="waleed" />
            <input type="hidden" name="email" value="waleedsdev@gmail.com" />
            <input type="hidden" name="phone" value="7498608775" />
            <input type="hidden" name="surl" value="http://localhost:3000/api/payment/success" />
            <input type="hidden" name="furl" value="http://localhost:3000/api/payment/failure" />
            <input type="hidden" name="udf1" value="total room: 2" />
            <input type="hidden" name="udf2" value="double sharing room" />
            <input type="hidden" name="udf3" value="trip id: 45e4f5e4f5we4fw5ef45w" />
            <input type="hidden" name="hash" value={hashKey} />
            <button type='submit'>Pay Now</button>
          </form>
    </div>
    </>
  )
}

export default PaymentPage
