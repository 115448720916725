import React, { useEffect, useState } from 'react'
import '../../Style/LandingPage/Explore.css'
import { Link } from 'react-router-dom';
import axios from 'axios';

const Explore = () => {
    const [top5HomeCategory, setTop5HomeCategory] = useState(null);
    const [loading, setLoading] = useState(null);
    const [allCategory, setAllCategory] = useState([])
    // get All Selected Home Page Category
    const getSelectedHomeCategory = () => {
        axios.get(`api/homecategory/get-home-category`).then((res) => {
            setTop5HomeCategory(res.data.data[0]?.curatedCategory)
        })
    }

    // get all category
    const getAllCategory = async () => {
        setLoading(true)
        await axios.get(`api/category/get-all-category`).then((res) => {
            if (res.data.data) {
                setAllCategory(res.data.data)
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }

    useEffect(() => {
        getSelectedHomeCategory();
        getAllCategory();
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className='my-5'>
                <span className='category__heading text-center'>Exclusive Categories</span>
                <div className='hr-line'></div>
                <div className="explore__container row d-flex">
                    {
                        top5HomeCategory?.map(category => {
                            const data = allCategory?.find(obj => obj?.name === category)

                            return <div key={category} className="one col-4 col-sm-4 col-md-4 col-lg-2 explore__feature p-3">
                                <Link to={`/category/${category.replace(/\s+/g, '-')}`}>
                                    {loading? <div>
                                        <div className='skeleton'>

                                        </div>
                                    </div> : <img src={data?.url} alt="Loading" />}
                                </Link>
                                <span className='content_text_grey my-2'>{category}</span>
                            </div>
                        })
                    }
                </div>
            </div>
        </>

    )
}

export default Explore
