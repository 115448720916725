import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import '../../Style/SingleTrip/Gallery.css';

import { Modal, Button } from 'react-bootstrap';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use([Navigation, Pagination]);

const Gallery = ({ trip }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [imageUrls, setImageUrls] = useState([]);
    const arrowRef = useRef();

    useEffect(() => {
        // Extract the URLs from the trip.gallery data and store them in the imageUrls array
        const urls = trip?.gallery?.map((item) => {
            if(typeof item === 'string'){
                const new_url = item?.replace(/ /g, '%20')
                return `https://tripgix.com/trip/gallery/${new_url}`
            }
            
        });
        setImageUrls(urls);
    }, [trip?.gallery]);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        rows: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.8,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.7,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        handleOpenModal();
    };
    return (
        <div className='my-5 video__cont'>
            <div>
                {
                    trip?.gallery?.length > 0 &&
                    <>
                        <h2 className='Mon_font'>Gallery</h2>
                        <div className='hr-line-head'>
                            <div className="hr-line m-0 mb-3 "></div>
                        </div>
                    </>
                }
                <div className="img__gallery">
                    <Slider ref={arrowRef} {...settings}>
                        {
                            trip?.gallery?.map((item, index) => {
                                return (
                                    <img src={`gallery/${item}`} alt="" className='m-3' width={200} onClick={() => handleImageClick(index)} />
                                )
                            })
                        }
                    </Slider>
                    {
                        trip?.gallery?.length >= 4 &&
                        <div>
                            <button onClick={() => arrowRef.current.slickPrev()} className='arrow__left'><i className="fa-solid fa-chevron-left"></i></button>
                            <button onClick={() => arrowRef.current.slickNext()} className='arrow__right'><i className="fa-solid fa-chevron-right"></i></button>
                        </div>
                    }
                </div>
                <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                    <Modal.Body>
                    {trip &&
                        <div>
                            <img src={ imageUrls && imageUrls[selectedImageIndex]} alt={`Loading ${selectedImageIndex + 1}`} style={{ width: '100%', height: 'auto' }}/>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default Gallery
